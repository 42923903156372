import { FC, ReactNode } from 'react';
import styles from './Main.module.scss';
import classNames from 'classnames';
import Container from '../Container/Container';

interface MainProps {
	children: ReactNode;
	className?: string;
}

const Main: FC<MainProps> = ({ children, className }) => {
	return (
		<main className={classNames(styles.main, className)}>
			<Container>{children}</Container>
		</main>
	);
};

export default Main;
