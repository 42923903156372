import { FC, useRef, useState } from 'react';
import styles from './FlatSwiper.module.scss';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperPrev from '../SwiperPrev/SwiperPrev';
import SwiperNext from '../SwiperNext/SwiperNext';
import FlatCard from '../FlatCard/FlatCard';
import SeeAllLink from '../../ui/SeeAllLink/SeeAllLink';
import Wrapper from '../../containers/Wrapper/Wrapper';

interface FlatSwiperProps {
	children?: any;
}

const FlatSwiper: FC<FlatSwiperProps> = ({ children }) => {
	const swiperRef = useRef<SwiperCore>();

	const [swiperNavigationAllowed, setSwiperNavigationAllowed] = useState<any>({
		prevButtonDisabled: true,
		nextButtonDisabled: false
	});

	return (
		<Wrapper>
			<Swiper
				spaceBetween={20}
				slidesPerView={'auto'}
				scrollbar={{ draggable: true }}
				onBeforeInit={(swiper: SwiperCore | undefined) => {
					swiperRef.current = swiper;
				}}
				onReachEnd={() =>
					setSwiperNavigationAllowed({
						...swiperNavigationAllowed,
						nextButtonDisabled: true
					})
				}
				onReachBeginning={() =>
					setSwiperNavigationAllowed({
						...swiperNavigationAllowed,
						prevButtonDisabled: true
					})
				}
				onSlideChange={() =>
					setSwiperNavigationAllowed({
						prevButtonDisabled: swiperRef?.current?.isBeginning,
						nextButtonDisabled: swiperRef?.current?.isEnd
					})
				}
			>
				{children.map((card, index) => {
					return (
						<SwiperSlide style={{ width: 'auto' }} key={index}>
							<FlatCard
								image={card.image}
								price={card.price}
								address={card.address}
								typeFlat={card.typeFlat}
								squareFlat={card.squareFlat}
								floorFlat={card.floorFlat}
								path={card.path}
							/>
						</SwiperSlide>
					);
				})}
			</Swiper>

			<SwiperPrev
				onClick={() => swiperRef.current?.slidePrev()}
				disabled={swiperNavigationAllowed.prevButtonDisabled}
				className={styles.pos}
			/>
			<SwiperNext
				onClick={() => swiperRef.current?.slideNext()}
				disabled={swiperNavigationAllowed.nextButtonDisabled}
				className={styles.pos}
			/>

			<SeeAllLink path={'/'} desktopNone />
		</Wrapper>
	);
};

export default FlatSwiper;
