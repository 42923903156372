import { FC, ReactNode } from 'react';
import styles from './Wrapper.module.scss';
import classNames from 'classnames';

interface WrapperProps {
	children: ReactNode;
	className?: string;
}

const Wrapper: FC<WrapperProps> = ({ children, className }) => {
	return (
		<div className={classNames(styles.wrapper, className)}>{children}</div>
	);
};

export default Wrapper;
