import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import AdBox from '../../../../components/AdBox/AdBox';
import adImg from '../../../../assets/images/ad/ad1.jpg';

const Ad = () => {
	return (
		<SectionWrapper>
			<AdBox image={adImg} />
		</SectionWrapper>
	);
};

export default Ad;
