import { FC, ReactNode } from 'react';
import styles from './CategoryCard.module.scss';
import classNames from 'classnames';

interface CategoryCardProps {
	icon: ReactNode;
	title: string;
	subtitle: string;
	path: string;
	className?: string;
	iconMobileNone?: boolean;
}

const CategoryCard: FC<CategoryCardProps> = ({
	icon,
	subtitle,
	title,
	path,
	className,
	iconMobileNone
}) => {
	return (
		<div className={classNames(styles.categoryCard, className)}>
			<a className={styles.link} href={path}></a>
			{!iconMobileNone && <i className={styles.icon}>{icon}</i>}
			<h2>{title}</h2>
			<p>{subtitle}</p>
		</div>
	);
};

export default CategoryCard;
