import { FC } from 'react';
import styles from './AdBox.module.scss';
import classNames from 'classnames';

interface AdBoxProps {
	image: string;
	className?: string;
}

const AdBox: FC<AdBoxProps> = ({ image, className }) => {
	return (
		<a className={classNames(styles.ad, className)} href={'/'}>
			<img src={image} alt='ad' />
		</a>
	);
};

export default AdBox;
