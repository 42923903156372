import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import SeeAllLink from '../../../../ui/SeeAllLink/SeeAllLink';
import FlatSwiper from '../../../../components/FlatSwiper/FlatSwiper';
import { rentalAdsData } from './RentalAds.data';
import SectionRow from '../../../../containers/SectionRow/SectionRow';

const RentalAds = () => {
	return (
		<SectionWrapper>
			<SectionRow>
				<h1>Новые объявления аренды</h1>

				<SeeAllLink path={'/RentList'} mobileNone />
			</SectionRow>

			<FlatSwiper children={rentalAdsData} />
		</SectionWrapper>
	);
};

export default RentalAds;
