import { FC } from 'react';
import styles from './FlatCard.module.scss';

interface FlatCardProps {
	image: string;
	price: string;
	address: string;
	typeFlat: string;
	squareFlat: string;
	floorFlat: string;
	path: string;
}

const FlatCard: FC<FlatCardProps> = ({
	image,
	price,
	address,
	typeFlat,
	squareFlat,
	floorFlat,
	path
}) => {
	return (
		<div className={styles.flatCard}>
			<a className={styles.flatLink} href={path}></a>
			<div className={styles.imgWrapper}>
				<img src={image} alt='' />
			</div>

			<div className={styles.body}>
				<p className={styles.time}>2 часа назад</p>

				<div className={styles.about}>
					<span className={styles.status}>Проверено модерацией</span>

					<div className={styles.info}>
						<span>{typeFlat}</span>
						<span>{squareFlat}</span>
						<span>{floorFlat}</span>
					</div>

					<div className={styles.options}>
						<span className={styles.price}>{price} ₽</span>
						<span>Без комиссии</span>
						{/*<span>Залог 10 000 ₽</span>*/}
					</div>

					<p className={styles.address}>{address}</p>
				</div>
			</div>
		</div>
	);
};

export default FlatCard;
